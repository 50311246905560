import React, { useState } from 'react'
import { SiteHeader } from './header'

interface BootstrapAlertProps {
  class_name: string;
  hidden: boolean;
  alert_text: string;
}

function BootstrapAlert(props: BootstrapAlertProps){
  return (
    <div className={`text-center alert-dismissible fade show alert alert-${props.class_name} ${props.hidden ? 'd-none' : ''}`} role="alert">
      {props.alert_text}
    </div>
  )
}

export interface ContactFormState {
  email_input: string;
  email_subject: string;
  email_body: string;
  alert_text: string;
  alert_class: string;
  hide_alert: boolean;
  already_got_one: boolean;
}

function ContactForm(){
  const [state, setState] = useState<ContactFormState>({
    email_input: "",
    email_subject: "",
    email_body: "",
    alert_text: "",
    alert_class: "",
    hide_alert: false,
    already_got_one: false,
  });

  function handle_email_change(e: React.ChangeEvent<HTMLInputElement >){
    setState({
      ...state,
      email_input: e.target.value
    });
  }

  function handle_subject_change(e: React.ChangeEvent<HTMLInputElement>){
    setState({
      ...state,
      email_subject: e.target.value
    });
  }

  function handle_body_change(e: React.ChangeEvent<HTMLTextAreaElement>){
    setState({
      ...state,
      email_body: e.target.value
    });
  }

  function submitContact(event: React.SyntheticEvent<HTMLFormElement>){
    event.preventDefault();

    const apiEndpoint = "https://api." + window.location.host + "/contact";
    const contactData = {
      address: state.email_input,
      subject: state.email_subject,
      body: state.email_body
    }

    if(state.already_got_one){
      setState({
        ...state,
        alert_text: "He's already got one!",
        alert_class: "warning",
        hide_alert: false
      })
      return;
    } 

    const request = new Request(apiEndpoint, {
      method: "POST",
      body: JSON.stringify(contactData),
      headers: {
        "Content-Type": "application/json"
      }
    });

    fetch(request)
      .then((response) => response.json())
      .then((data) => {
        setState({
          ...state,
          alert_text: data.message,
          alert_class: "success",
          hide_alert: false,
          already_got_one: true,
          email_input: "",
          email_subject: "",
          email_body: ""
        });
      })
      .catch(() => {
        console.log("Bad things!");
        setState({
          ...state,
          alert_text: "Failed to submit contact!",
          alert_class: "danger",
          hide_alert: false,
          already_got_one: false
        });
      });
  }

  return (
    <div>
      <BootstrapAlert
        alert_text={state.alert_text}
        class_name={state.alert_class}
        hidden={state.hide_alert}
      />

      <form onSubmit={submitContact}>
        <div className="mb-3">
          <label htmlFor="email_input" className="form-label">Email address</label>
          <input
            placeholder="Email..."
            type="email"
            className="form-control"
            id="email_input"
            aria-describedby="email_input_help"
            name="email_input"
            value={state.email_input}
            onChange={handle_email_change}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email_subject" className="form-label">Subject</label>
          <input
            placeholder="Subject..."
            type="text"
            className="form-control"
            id="email_subject"
            aria-describedby="email_subject_help"
            name="email_subject"
            value={state.email_subject}
            onChange={handle_subject_change}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email_body" className="form-label">Body</label>
          <textarea
            placeholder="You know what to do here..."
            rows={5}
            className="form-control"
            id="email_body"
            aria-describedby="email_body_help"
            name="email_body"
            value={state.email_body}
            onChange={handle_body_change}
          />
        </div>

        <button type="submit" className="btn btn-primary">Contact</button>
      </form>
    </div>
  )
}

export function ContactPage(){
  return (
    <div className="container-md">
      <SiteHeader title="Contact me" />
      <ContactForm />
    </div>
  )
}
