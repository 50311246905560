import React, { StrictMode, useState } from 'react'
import { createRoot } from 'react-dom/client';

import 'bootstrap'

import './index.css'
import { HomePage } from './home_page'
import { SiteHeader } from './header'
import { ContactPage } from './contact'
import 'bootstrap/dist/css/bootstrap.min.css';


function Error404(){
  return (
    <div className="text-center container-md">
      <SiteHeader />
      <h1>Where am I?</h1>
      <p>(page not found)</p>
    </div>
  )
}

interface RenderProps {
  page_name: string;
}

function RenderPage(props: RenderProps){
  const page = props.page_name;

  switch(page){
    case 'Home':
      return <HomePage />;
    case 'Contact':
      return <ContactPage />;
    default:
      return <Error404 />;
  }
}

interface NavItemProps {
  active: boolean;
  disabled: boolean;
  clickNavItem: React.MouseEventHandler<HTMLAnchorElement>;
  value: string;
}

function NavItem(props: NavItemProps) {
  return (
    <li className="nav-item">
      <a
        className={`nav-link ${props.active === true ? 'active' : ''} ${props.disabled ? 'disabled' : ''}`}
        aria-current="page"
        href="/#"
        onClick={props.clickNavItem}
      >
        {props.value}
      </a>
    </li>
  );
}

function NavBar(){
  const [active_nav, set_active_nav] = useState("Home")

  function handleNavUpdate(navItem: string){
    set_active_nav(navItem)
  }

  const navItems = [
    { name: "Home" },
    { name: "Contact" },
    { name: "Projects (soon)", disabled: true },
  ]

  const navList = navItems.map((i) =>
    <NavItem
      value={i.name}
      key={i.name}
      disabled={i['disabled'] || false}
      clickNavItem={() => handleNavUpdate(i.name)}
      active={active_nav === i.name}
    />
  )

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/#">justinMBurrous.com</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              {navList}
            </ul>
          </div>
        </div>
      </nav>

      <RenderPage page_name={active_nav} />
    </div>
  )
}

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
  <StrictMode>
    <NavBar />
  </StrictMode>
);
