import { SiteHeader } from  './header'
import React from 'react'

export function HomePage(){
  return (
    <div className="container-md">
      <div>
        <SiteHeader title="justinMBurrous.com" />

        <div className="text-center">
          <p className="small-font-size text-muted">
            (one of those personal websites... in {new Date().getFullYear()}?)
          </p>

          <blockquote className="blockquote">
            <p>A software engineer in Seattle.</p>
          </blockquote>

          <a className="btn btn-secondary" href="https://github.com/justinmburrous">
            Github
          </a>
        </div>
      </div>
    </div>
  )
}
